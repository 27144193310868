import PropTypes from 'prop-types';
// material
import { Box } from '@material-ui/core';

// ----------------------------------------------------------------------

Logo.propTypes = {
  sx: PropTypes.object
};

export default function Logo({ sx }) {
  return (
    <Box sx={{ width: 90, ...sx }}>
      <img src="/static/icons/Qvende_logo.svg" alt="Qvende_logo" />
    </Box>
  );
}

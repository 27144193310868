import React, { useEffect, useState, useRef } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Icon } from '@iconify/react';
import bellFill from '@iconify/icons-eva/bell-fill';
import doneAllFill from '@iconify/icons-eva/done-all-fill';
import {
  Box,
  List,
  Badge,
  Button,
  Tooltip,
  Divider,
  Typography,
  ListItemText,
  ListItemButton
} from '@material-ui/core';
import { alpha } from '@material-ui/core/styles';
import Scrollbar from '../../components/Scrollbar';
import MenuPopover from '../../components/MenuPopover';
import { MIconButton } from '../../components/@material-extend';
import { useFetch } from '../../hooks/useFetch';

export default function NotificationsPopover() {
  const [notifications, setNotifications] = useState([]);
  const [expirationNotification, setExpirationNotification] = useState(false);
  const [open, setOpen] = useState(false);
  const [unreadCount, setUnreadCount] = useState(0);
  const anchorRef = useRef(null);

  const { data: signatures } = useFetch(`/plan/getCompanies`);

  useEffect(() => {
    if (signatures) {
      const today = new Date();
      const expirationThreshold = 20;

      const expiringSubscriptions = signatures?.filter((signature) => {
        const startDate = new Date(signature.creationDate);
        const endDate = new Date(startDate);
        endDate.setMonth(startDate.getMonth() + signature.plan.durationInMonth);

        const timeDiff = endDate.getTime() - today.getTime();
        const diffDays = Math.ceil(timeDiff / (1000 * 3600 * 24));

        return diffDays === expirationThreshold;
      });

      if (expiringSubscriptions?.length > 0) {
        setExpirationNotification(true);
        setNotifications(
          expiringSubscriptions.map((signature) => ({
            id: signature.id,
            type: 'Expiration',
            message: `A assinatura da empresa ${signature.company.name} vai expirar em 20 dias`,
            time: new Date().toLocaleString(),
            link: `/dashboard/subscriptions`,
            companyName: signature.company.name,
            read: false
          }))
        );
      } else {
        setExpirationNotification(false);
      }
    }
  }, [signatures]);

  useEffect(() => {
    const unreadNotifications = notifications.filter((notification) => !notification.read);
    setUnreadCount(unreadNotifications.length);
  }, [notifications]);

  useEffect(() => {
    const savedNotifications = JSON.parse(localStorage.getItem('notifications'));
    if (savedNotifications) {
      setNotifications(savedNotifications);
    }
  }, []);

  const handleMarkAllAsRead = () => {
    const updatedNotifications = notifications.map((notification) => ({ ...notification, read: true }));
    setNotifications(updatedNotifications);
    setUnreadCount(0);
    localStorage.setItem('notifications', JSON.stringify(updatedNotifications));
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
    handleMarkAllAsRead();
  };

  return (
    <>
      <MIconButton
        ref={anchorRef}
        size="large"
        color="primary"
        onClick={handleOpen}
        sx={{
          ...(open && {
            bgcolor: (theme) => alpha(theme.palette.primary.main, theme.palette.action.focusOpacity)
          })
        }}
      >
        <Badge
          badgeContent={unreadCount}
          sx={{
            '.MuiBadge-badge': {
              color: 'white',
              background: (theme) => theme.palette.secondary.light
            }
          }}
        >
          <Icon icon={bellFill} width={20} height={20} />
        </Badge>
      </MIconButton>

      <MenuPopover open={open} onClose={handleClose} anchorEl={anchorRef.current} sx={{ width: 360 }}>
        <Box sx={{ display: 'flex', alignItems: 'center', py: 2, px: 2.5 }}>
          <Box sx={{ flexGrow: 1 }}>
            <Typography variant="subtitle1">Notificações</Typography>
            <Typography variant="body2" sx={{ color: 'text.secondary' }}>
              Você tem {unreadCount} notificações não lidas
            </Typography>
          </Box>

          {unreadCount > 0 && (
            <Tooltip title=" Marcar todas como lidas">
              <Button color="primary" onClick={handleMarkAllAsRead}>
                <Icon icon={doneAllFill} width={20} height={20} />
              </Button>
            </Tooltip>
          )}
        </Box>
        <Divider />

        <Scrollbar sx={{ maxHeight: { xs: 340, sm: 500 } }}>
          <List disablePadding>
            {notifications.map((notification) => (
              <ListItemButton key={notification.id} component={RouterLink} to={notification.link}>
                <ListItemText primary={notification.message} secondary={notification.time} />
              </ListItemButton>
            ))}
          </List>
        </Scrollbar>

        <Divider />
      </MenuPopover>
    </>
  );
}

import { useEffect, useState } from 'react';
import { qvendeApi } from '../utils/api';

export function useFetch(url, options, callback) {
  const [data, setData] = useState();
  const [isFetching, setIsFetching] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    qvendeApi
      .get(url, options)
      .then((response) => {
        setData(response.data);
        if (callback) callback(response.data);
      })
      .catch((err) => {
        console.log(url, err);
        setError(err);
      })
      .finally(() => setIsFetching(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [options, url]);

  return { data, isFetching, error };
}

import { Helmet } from 'react-helmet-async';
import * as Sentry from '@sentry/react';
// routes
import Router from './routes';
// theme
import ThemeConfig from './theme';
// hooks
import useAuth from './hooks/useAuth';

// components
// import Settings from './components/settings';
import RtlLayout from './components/RtlLayout';
import ScrollToTop from './components/ScrollToTop';
import LoadingScreen from './components/LoadingScreen';
import GoogleAnalytics from './components/GoogleAnalytics';
import NotistackProvider from './components/NotistackProvider';
import ThemePrimaryColor from './components/ThemePrimaryColor';
import ThemeLocalization from './components/ThemeLocalization';

// ----------------------------------------------------------------------

function App() {
  const { isInitialized } = useAuth();

  return (
    <ThemeConfig>
      <ThemePrimaryColor>
        <ThemeLocalization>
          <RtlLayout>
            <NotistackProvider>
              {/* <Settings /> */}
              <ScrollToTop />
              <GoogleAnalytics />
              <Helmet>
                <script>
                  {`
                    (function(c,l,a,r,i,t,y){
                      c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
                      t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
                      y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
                    })(window, document, "clarity", "script", "${process.env.REACT_APP_CLARITY_ID}");
                  `}
                </script>
                {process.env.REACT_APP_ENVIRONMENT === 'staging' ? (
                  <>
                    <script src="https://unpkg.com/feedbit-script/index.js" async />
                    <script>
                      {`
                        modal({
                          api_url: '${process.env.REACT_APP_FEEDYBUG_API}',
                          applicationToken: '${process.env.REACT_APP_FEEDYBUG_TOKEN}',
                          position: 'right',
                          theme: 'dark'
                        }, document, window);
                      `}
                    </script>
                  </>
                ) : (
                  <>
                    <script src="https://unpkg.com/feedbit-script/index.js" async />
                    <script>
                      {`
                        modal({
                          api_url: '${process.env.REACT_APP_FEEDYBUG_API}',
                          applicationToken: '${process.env.REACT_APP_FEEDYBUG_TOKEN}',
                          position: 'right',
                          theme: 'dark'
                        }, document, window);
                      `}
                    </script>
                  </>
                )}
              </Helmet>
              {isInitialized ? <Router /> : <LoadingScreen />}
            </NotistackProvider>
          </RtlLayout>
        </ThemeLocalization>
      </ThemePrimaryColor>
    </ThemeConfig>
  );
}
export default Sentry.withProfiler(App);

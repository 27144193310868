import React from 'react';
import PropTypes from 'prop-types';
// material
import { Typography, Stack } from '@material-ui/core';

LabelFields.propTypes = {
  title: PropTypes.string,
  required: PropTypes.bool,
  sx: PropTypes.object
};

export default function LabelFields({ title, required, sx }) {
  return (
    <Stack direction="row" sx={sx}>
      <Typography variant="body2" color="primary" fontWeight="600">
        {title}
      </Typography>
      {required && <Typography color="primary">*</Typography>}
    </Stack>
  );
}

import { Suspense, lazy } from 'react';
import { Navigate, useRoutes, useLocation } from 'react-router-dom';
// layouts
import MainLayout from '../layouts/main';
import DashboardLayout from '../layouts/dashboard';
import LogoOnlyLayout from '../layouts/LogoOnlyLayout';
// guards
import GuestGuard from '../guards/GuestGuard';
import AuthGuard from '../guards/AuthGuard';
// import RoleBasedGuard from '../guards/RoleBasedGuard';
// components
import LoadingScreen from '../components/LoadingScreen';

// ----------------------------------------------------------------------

const Loadable = (Component) => (props) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { pathname } = useLocation();
  const isDashboard = pathname.includes('/dashboard');

  return (
    <Suspense
      fallback={
        <LoadingScreen
          sx={{
            ...(!isDashboard && {
              top: 0,
              left: 0,
              width: 1,
              zIndex: 9999,
              position: 'fixed'
            })
          }}
        />
      }
    >
      <Component {...props} />
    </Suspense>
  );
};

export default function Router() {
  return useRoutes([
    {
      path: 'auth',
      children: [
        {
          path: 'login',
          element: (
            <GuestGuard>
              <Login />
            </GuestGuard>
          )
        },
        { path: 'reset-password', element: <ResetPassword /> },
        { path: 'change-password', element: <ChangePassword /> }
      ]
    },

    // Dashboard Routes
    {
      path: 'dashboard',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        {
          path: 'user',
          children: [{ path: 'account', element: <UserAccount /> }]
        },
        {
          path: 'subscriptions',
          children: [{ path: '/', element: <Subscriptions /> }]
        },
        {
          path: 'plans',
          children: [{ path: '/', element: <Plans /> }]
        },
        {
          path: 'logsEmails',
          children: [{ path: '/', element: <LogsEmails /> }]
        },
        {
          path: 'logging',
          children: [{ path: '/', element: <Logging /> }]
        },
        {
          path: 'logsErrosEmails',
          children: [{ path: '/', element: <LogsErrosEmails /> }]
        },
        {
          path: 'logsWhatsapp',
          children: [{ path: '/', element: <LogsWhatsapp /> }]
        },
        {
          path: 'logsCatho',
          children: [{ path: '/', element: <LogsCatho /> }]
        },
        {
          path: 'vouchers',
          children: [{ path: '/', element: <Voucher /> }]
        },
        {
          path: 'user',
          children: [{ path: 'account', element: <UserAccount /> }]
        },
        {
          path: 'settings',
          children: [
            { path: '/', element: <Settings /> },
            { path: '/weights', element: <Weights /> }
          ]
        },
        {
          path: 'listSelectiveProcess',
          children: [
            { path: '/', element: <ListSelectiveProcess /> },
            { path: '/ViewSelectiveProcess/:selectiveProcessId', element: <ViewSelectiveProcessGlobal /> }
          ]
        },
        {
          path: 'dashboarding',
          children: [{ path: '/', element: <Dashboarding /> }]
        },
        {
          path: 'sendMessage',
          children: [
            { path: '/', element: <Navigate to="/dashboard/sendMessage/1/all" replace /> },
            { path: ':typeUser/label/:customLabel', element: <SendMessage /> },
            { path: ':typeUser/label/:customLabel/:mailId', element: <SendMessage /> },
            { path: ':typeUser/:systemLabel', element: <SendMessage /> },
            { path: ':typeUser/:systemLabel/:mailId', element: <SendMessage /> }
          ]
        },
        {
          path: 'users',
          children: [
            {
              path: 'super-admins',
              children: [
                { path: '/', element: <ListUsers /> },
                { path: 'new', element: <NewSuperAdmin /> }
              ]
            },
            {
              path: 'candidates',
              children: [
                { path: '/', element: <SearchCandidates /> },
                {
                  path: '/:candidateId',
                  children: [
                    { path: '/', element: <ViewCandidate /> },
                    { path: '/editCandidate', element: <EditCandidate /> },
                    { path: '/candidateSettings', element: <CandidateSettings /> },
                    { path: '/addVideoResume', element: <AddVideoResume /> },
                    { path: '/viewVideoResume', element: <ViewVideoResume /> },
                    {
                      path: '/pregressSelectiveProcess/:selectiveProcessId',
                      children: [
                        { path: '/', element: <ProgressSelectiveProcess /> },
                        { path: '/auxiliaryRequirementsTest', element: <AuxiliaryRequirementsTest /> },
                        { path: '/auxiliaryQuestionsTest', element: <AuxiliaryQuestionsTest /> },
                        { path: '/addVideoInterview', element: <AddVideoInterview /> },
                        { path: '/viewVideoInterview', element: <ViewVideoInterview /> },
                        { path: '/customTestQuestions', element: <CustomTestQuestions /> },
                        { path: '/valuesTestIntro', element: <ValuesTestCandidateIntro /> },
                        { path: '/valuesTestAnswer', element: <ValuesTestCandidate /> }
                      ]
                    },
                    { path: '/competencyTest', element: <CompetencyTest /> }
                  ]
                },
                { path: '/register', element: <RegisterCandidate /> }
              ]
            }
          ]
        },
        {
          path: 'companies',
          children: [
            { path: '/', element: <SearchCompanies /> },
            { path: '/register', element: <RegisterCompany /> },
            {
              path: '/:companyId',
              children: [
                { path: '/', element: <ViewCompany /> },
                { path: '/valuesTest', element: <ValuesTest /> },
                { path: '/listSelectiveProcesses', element: <ListSelectiveProcesses /> },
                { path: '/registerSelectiveProcess', element: <RegisterSelectiveProcess /> },
                {
                  path: '/viewSelectiveProcess/:selectiveProcessId',
                  children: [{ path: '/', element: <ManagerSelectiveProcess /> }]
                },
                { path: '/viewSelectiveProcess/:selectiveProcessId/update', element: <UpdateSelectiveProcess /> },
                { path: '/editCompany', element: <EditCompany /> },
                {
                  path: 'questionSheet',
                  children: [
                    { path: '/', element: <QuestionSheet /> },
                    { path: '/customTest', element: <CustomTest /> }
                  ]
                }
              ]
            }
          ]
        }
      ]
    },

    // Main Routes
    {
      path: '*',
      element: <LogoOnlyLayout />,
      children: [
        { path: '404', element: <NotFound /> },
        { path: '*', element: <Navigate to="/404" replace /> }
      ]
    },
    {
      path: '/',
      element: <MainLayout />,
      children: [{ path: '/', element: <Navigate to="/auth/login" /> }]
    },
    { path: '*', element: <Navigate to="/404" replace /> }
  ]);
}

// IMPORT COMPONENTS

// Authentication
const Login = Loadable(lazy(() => import('../pages/authentication/Login')));
// const Register = Loadable(lazy(() => import('../pages/authentication/Register')));
const ResetPassword = Loadable(lazy(() => import('../pages/authentication/ResetPassword')));
const ChangePassword = Loadable(lazy(() => import('../pages/authentication/ChangePassword')));
const UserAccount = Loadable(lazy(() => import('../pages/dashboard/Admin/UserAccount')));
// Dashboard
const ListSelectiveProcess = Loadable(lazy(() => import('../pages/dashboard/ListSelectiveProcess')));
const ViewSelectiveProcessGlobal = Loadable(lazy(() => import('../pages/dashboard/ViewSelectiveProcessGlobal')));
const SendMessage = Loadable(lazy(() => import('../pages/dashboard/SendMessage')));
const Plans = Loadable(lazy(() => import('../pages/dashboard/Plans')));
const Voucher = Loadable(lazy(() => import('../pages/dashboard/Voucher')));
const Dashboarding = Loadable(lazy(() => import('../pages/dashboard/Dashboarding')));
const LogsEmails = Loadable(lazy(() => import('../pages/dashboard/LogsEmails')));
const LogsErrosEmails = Loadable(lazy(() => import('../pages/dashboard/LogsErrosEmails')));
const LogsWhatsapp = Loadable(lazy(() => import('../pages/dashboard/LogsWhatsapp')));
const LogsCatho = Loadable(lazy(() => import('../pages/dashboard/LogsCatho')));
const Logging = Loadable(lazy(() => import('../pages/dashboard/Logging')));
// Candidate
const CandidateSettings = Loadable(lazy(() => import('../pages/dashboard/Candidate/CandidateSettings')));
const EditCandidate = Loadable(lazy(() => import('../pages/dashboard/Candidate/EditCandidate')));
const SearchCandidates = Loadable(lazy(() => import('../pages/dashboard/Candidate/SearchCandidates')));
const ViewCandidate = Loadable(lazy(() => import('../pages/dashboard/Candidate/ViewCandidate')));
const RegisterCandidate = Loadable(lazy(() => import('../pages/dashboard/Candidate/RegisterCandidate')));
const ProgressSelectiveProcess = Loadable(lazy(() => import('../pages/dashboard/Candidate/ProgressSelectiveProcess')));
const CompetencyTest = Loadable(lazy(() => import('../pages/dashboard/Candidate/CompetencyTest/index')));
const AuxiliaryQuestionsTest = Loadable(lazy(() => import('../pages/dashboard/Candidate/AuxiliaryQuestionsTest')));
const AddVideoInterview = Loadable(lazy(() => import('../pages/dashboard/Candidate/AddVideoInterview')));
const ViewVideoInterview = Loadable(lazy(() => import('../pages/dashboard/Candidate/ViewVideoInterview')));
const CustomTestQuestions = Loadable(lazy(() => import('../pages/dashboard/Candidate/CustomTestQuestions')));
const AddVideoResume = Loadable(lazy(() => import('../pages/dashboard/Candidate/AddVideoResume')));
const ViewVideoResume = Loadable(lazy(() => import('../pages/dashboard/Candidate/ViewVideoResume')));
const AuxiliaryRequirementsTest = Loadable(
  lazy(() => import('../pages/dashboard/Candidate/AuxiliaryRequirementsTest'))
);
const ValuesTestCandidateIntro = Loadable(lazy(() => import('../pages/dashboard/Candidate/ValuesTest/index')));
const ValuesTestCandidate = Loadable(lazy(() => import('../pages/dashboard/Candidate/ValuesTest/ValuesTest')));
// Companie
const EditCompany = Loadable(lazy(() => import('../pages/dashboard/Company/EditCompany')));
const ValuesTest = Loadable(lazy(() => import('../pages/dashboard/Company/ValuesTest')));
const ViewCompany = Loadable(lazy(() => import('../pages/dashboard/Company/ViewCompany')));
const RegisterSelectiveProcess = Loadable(lazy(() => import('../pages/dashboard/Company/RegisterSelectiveProcess')));
const ListSelectiveProcesses = Loadable(lazy(() => import('../pages/dashboard/Company/ListSelectiveProcesses')));
const UpdateSelectiveProcess = Loadable(lazy(() => import('../pages/dashboard/Company/UpdateSelectiveProcess')));
const SearchCompanies = Loadable(lazy(() => import('../pages/dashboard/Company/SearchCompanies')));
const RegisterCompany = Loadable(lazy(() => import('../pages/dashboard/Company/RegisterCompany')));
const ManagerSelectiveProcess = Loadable(lazy(() => import('../pages/dashboard/Company/ManagerSelectiveProcess')));
const QuestionSheet = Loadable(lazy(() => import('../pages/dashboard/Company/QuestionSheet')));
const CustomTest = Loadable(lazy(() => import('../pages/dashboard/Company/CustomTest')));
// Users
const ListUsers = Loadable(lazy(() => import('../pages/dashboard/Admin/ListUsers')));
const NewSuperAdmin = Loadable(lazy(() => import('../pages/dashboard/Admin/NewSuperAdmin')));
// Settings
const Settings = Loadable(lazy(() => import('../pages/dashboard/settings/index')));
const Weights = Loadable(lazy(() => import('../pages/dashboard/settings/weights')));
// Subscriptions
const Subscriptions = Loadable(lazy(() => import('../pages/dashboard/Subscriptions/index')));
// Main
const NotFound = Loadable(lazy(() => import('../pages/Page404')));

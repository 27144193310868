import { createContext, useEffect, useReducer } from 'react';
import PropTypes from 'prop-types';
// utils
import { qvendeApi } from '../utils/api';
import { isValidToken, setSession } from '../utils/jwt';

// ----------------------------------------------------------------------

const initialState = {
  isAuthenticated: false,
  isInitialized: false,
  user: null
};

const handlers = {
  INITIALIZE: (state, action) => {
    const { isAuthenticated, user } = action.payload;
    return {
      ...state,
      isAuthenticated,
      isInitialized: true,
      user
    };
  },
  LOGIN: (state, action) => {
    const { user } = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      user
    };
  },
  LOGOUT: (state) => ({
    ...state,
    isAuthenticated: false,
    user: null
  }),
  REGISTER: (state, action) => {
    const { user } = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      user
    };
  },
  ADD_PHOTO: (state, action) => {
    const { preview } = action.payload;

    return {
      ...state,
      user: {
        ...state.user,
        photoUrl: preview
      }
    };
  }
};

const reducer = (state, action) => (handlers[action.type] ? handlers[action.type](state, action) : state);

const AuthContext = createContext({
  ...initialState,
  method: 'jwt',
  login: () => Promise.resolve(),
  logout: () => Promise.resolve(),
  register: () => Promise.resolve()
});

AuthProvider.propTypes = {
  children: PropTypes.node
};

function AuthProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    initialize();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const initialize = async () => {
    try {
      const accessToken = window.localStorage.getItem('accessToken');

      if (accessToken && isValidToken(accessToken)) {
        setSession(accessToken, logout);
        const date = Date.now();
        const response = await qvendeApi.get(`/user/getUserByAcessToken/?accessToken=${accessToken}`);
        const user = response.data;
        dispatch({
          type: 'INITIALIZE',
          payload: {
            isAuthenticated: true,
            user: {
              ...user,
              photoUrl: `${user.photoUrl}?${date}`
            }
          }
        });
      } else {
        dispatch({
          type: 'INITIALIZE',
          payload: {
            isAuthenticated: false,
            user: null
          }
        });
      }
    } catch (err) {
      console.error(err);
      dispatch({
        type: 'INITIALIZE',
        payload: {
          isAuthenticated: false,
          user: null
        }
      });
    }
  };

  const login = async (email, password) => {
    const response = await qvendeApi.post('/authentication/login?typeUser=0', {
      email,
      password
    });
    const { accessToken, user } = response?.data;

    setSession(accessToken, logout);
    dispatch({
      type: 'LOGIN',
      payload: {
        user
      }
    });
  };

  const register = async (email, password, firstName, lastName) => {
    const response = await qvendeApi.post('/api/account/register', {
      email,
      password,
      firstName,
      lastName
    });
    const { accessToken, user } = response.data;

    window.localStorage.setItem('accessToken', accessToken);
    dispatch({
      type: 'REGISTER',
      payload: {
        user
      }
    });
  };

  const addPhoto = async (preview) => {
    dispatch({
      type: 'ADD_PHOTO',
      payload: {
        preview
      }
    });
  };

  const logout = async () => {
    setSession(null);
    dispatch({ type: 'LOGOUT' });
  };

  const resetPassword = async (email) => {
    await qvendeApi.post('user/forgotPassword', {
      email,
      appUrl: process.env.REACT_APP_BASE_URL,
      route: `auth/change-password/?key=`
    });
  };

  const updateProfile = async (superAdmin, superAdminId) => {
    await qvendeApi.put(`super-admin/${superAdminId}`, superAdmin);
    initialize();
  };

  const updateProfilePhoto = async (formData, userId, preview) => {
    addPhoto(preview);
    await qvendeApi.post(`user/${userId}/photo`, formData, {
      headers: { 'X-Requested-With': 'XMLHttpRequest' }
    });
    initialize();
  };

  const updatePassword = async (user, userId) => {
    await qvendeApi.put(`user/${userId}`, user);
    initialize();
  };

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: 'jwt',
        login,
        logout,
        register,
        resetPassword,
        updateProfile,
        updatePassword,
        updateProfilePhoto
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export { AuthContext, AuthProvider };
